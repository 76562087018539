import React, {useState} from 'react';
import { Outlet } from 'react-router-dom'

import UserClass from './services/user'
import Database from './services/db'

export default function App() {
    const userClass = new UserClass()
    const [user, setUser] = useState(userClass.currentUser)

    const db = new Database()

    const arrayMateriasInicial = [
        {'id': 1,'disciplina': 'Vazio', 'peso': 'x', 'nota': 'x'},
        {'id': 2,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
        {'id': 3,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
        {'id': 4,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
        {'id': 5,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
        {'id': 6,'disciplina': ' ', 'peso': ' ', 'nota': ' '}
    ]
    const [cadernoVazio, setCadernoVazio] = useState(user? false: true)
    const [materias, setMaterias] = useState(arrayMateriasInicial)


    // const login = () => userClass.login(user, setUser, setMaterias, db.carregarHistorico)
    const login = () => userClass.login(user, setUser, setMaterias)
    
    const logout = () => userClass.logout(user, setUser)

    const salvarHistorico = () => db.salvarHistorico(user? user.email : null, materias)

    return (
        <Outlet context={[arrayMateriasInicial, materias, setMaterias, cadernoVazio, setCadernoVazio, user, login, logout, salvarHistorico]}/>
    )
}