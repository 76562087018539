import './mostradorNota.css'
import animacoesLoadingClass from '../animacoes'

export default function MostradorNota(props) {
    const animacoes = new animacoesLoadingClass(props.inHomePage)
    let notaMostrador = props.notaFinal.toString().replace('.', ',')
    let nota = parseFloat(props.notaFinal.toString().replace(',', '.'))
    return (
        <div className='mostrador-parent'>
            <div className='mostrador limparMaterias' onClick = {props.resetMaterias}>Limpar tudo</div>
            <div
                className='mostrador mostradorNota'
                onClick = {
                    props.cadernoVazio? () => null:animacoes.animacaoLimpartudo
                }>
                NOTA:<span 
                    className={'span-mostrador-nota ' + (nota < 4? 'nota-baixa': (nota >= 9 ? 'nota-alta' : ''))
                }>{notaMostrador}</span>
            </div>
        </div>
    )
}