import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './App.css';

import App from './App'
import HomePage from './homePage/homePage'
import Calculadora from './calculadora/calculadora'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/calculadora/:historicoURL?', // colocando "?" no final, vira parametro opcional
        element: <Calculadora />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  //   <RouterProvider router = {router} />
  // </React.StrictMode>
  <RouterProvider router = {router} />
);

reportWebVitals();