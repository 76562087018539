import {useEffect} from 'react'
import {useNavigate, useOutletContext} from 'react-router-dom'

import './homePage.css'

import Header from '../components/header'
import Caderno from '../calculadora/caderno'
import FolhasInputMateria from '../calculadora/inputMateria'
import MostradorNotaHero from './mostradorNotaHero'

import animacoesLoadingClass from '../animacoes'

import setaCTA from '../imagens/seta-cta-desktop.svg'
import setaBaixo from '../imagens/seta-baixo-desktop-index.svg'
import setaCima from '../imagens/seta-cima-desktop-index.svg'


export default function HomePage() {
    const animacoesLoading = new animacoesLoadingClass()

    const  [, , , , , user, login, logout, ] = useOutletContext()
    // O uso das virgulas sem nenhum nome é equivalente ao _, em python
    // usado para pegar apenas as variaveis necessárias e jogar fora o resto

    const navigate = useNavigate()

    function irParaCalculadora() {
        animacoesLoading.animacaoHomePageParaCalc()
        setTimeout(() => {
            navigate('/calculadora')
        }, 550)
    }

    useEffect(() => {
        animacoesLoading.animacaoEntradaHomePage()
    })

    function TextoHero() {
        return (
            <div className = 'hero-texto'>
                <h1>Planeje suas</h1>
                <h1>notas de</h1>
                <h1>forma prática</h1>
                <h1>no <span className='destaque'>meucr.com</span></h1>
                <h2>
                    Bastam apenas
                    <span className='destaque'> 2 cliques </span>
                    para calcular seu coeficiente de rendimento.
                </h2>
                <button
                    className='btn-cta'
                    onClick={() => irParaCalculadora()}
                >
                    COMEÇAR AGORA <img src={setaCTA} className='seta-cta' alt='seta cta'/>
                </button>
            </div>
        )
    }
    
    function ImgHero(){
        const arrayMateriasHomePage = [
            {'id': 1,'disciplina':'Cálculo II', 'peso': 3, 'nota':9.8},
            {'id': 2,'disciplina': 'Genética I', 'peso': 4, 'nota': 7.4},
            {'id': 3,'disciplina': 'Algoritmos III', 'peso': 2, 'nota': 3.6},
            {'id': 4,'disciplina': 'História geral', 'peso': 2, 'nota': 8.5},
            {'id': 5,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
            {'id': 6,'disciplina': ' ', 'peso': ' ', 'nota': ' '}
        ]
    
        return (
            <div className='img-hero'>
                <div className='quadrado-ghost'></div>
                <div className='quadrado-amarelo'></div>
                <div className='quadrado-vermelho'></div>
                <div className='quadrado-azul'></div>
                <img src={setaCima} className='seta-cima' alt='seta cima' />
                <img src={setaBaixo} className='seta-baixo' alt='seta Baixo' />
                <div className='container-Caderno'><Caderno materias = {arrayMateriasHomePage}/></div>
                <MostradorNotaHero />
                <div className='container-FolhasInputMateria'>
                    <FolhasInputMateria />
                </div>
            </div>
        )
    }

    return (
        <>
            <Header
                inHomePage={true}
                irParaCalculadora={irParaCalculadora}
                irParaHomePage={() => null}
                user = {user}
                login = {login}
                logout = {logout}
            />
            <main>
                <TextoHero />
                <ImgHero />
            </main>
        </>
    )
}