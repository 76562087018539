import React, {useEffect, useState} from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import './calculadora.css'

import FolhasInputMateria from './inputMateria'
import Caderno from './caderno'
import MostradorNota from './mostradorNota'
import BtnGerarLink from './btnGerarLink'
import Header from '../components/header'

import animacoesLoadingClass from '../animacoes'

function calcularNota(listaMaterias) {
    let somaNotas = 0
    let pesoTotal = 0
    listaMaterias.forEach((mat) => {
        if (!isNaN(mat.nota)) {
            somaNotas += (mat.peso * mat.nota)
            pesoTotal += mat.peso
        }
    })
    if (pesoTotal > 0) {
        return Math.round((somaNotas / pesoTotal)*100) / 100
    }
    return 'X'
}

export default function Calculadora() {
    const navigate = useNavigate()
    const animacoes = new animacoesLoadingClass()


    const [msgErro, setMsgErro] = useState(false)
    const [arrayMateriasInicial, materias, setMaterias, cadernoVazio, setCadernoVazio, user, login, logout, salvarHistorico] = useOutletContext()
    const [notaFinal, setNotaFinal] = useState(calcularNota(materias))
    const [nomeOriginal, setNomeOriginal] = useState(null)
    // Função necessária para quanto o usuário quiser fazer alterações no nome da materia
    const [disciplinaInput, setDisciplinaInput] = useState({
        'disciplina':'',
        'peso':'',
        'nota':''
    })

    animacoes.animacaoEntradaCalc()

    useEffect(() => {
        setNotaFinal(calcularNota(materias))
    }, [materias])

    function validarDados(novaMateria) {
        if (
            (novaMateria.disciplina[0] === undefined) || (novaMateria.disciplina[0] === '') ||
            (novaMateria.peso[0] === undefined) || (novaMateria.peso[0] === '') ||
            (novaMateria.nota[0] === undefined) || (novaMateria.nota[0] === '')
        ) {
            if ((typeof novaMateria.peso !== 'number') && (typeof novaMateria.nota !== 'number') ) {
                return 'Por favor, preencha todos os campos.'
            }
        }
    
        novaMateria.disciplina = novaMateria.disciplina[0]
        if (novaMateria.disciplina !== nomeOriginal) {
            let materiaJaExiste = false
            materias.forEach((materia) => {
                if (materia.disciplina === novaMateria.disciplina) {materiaJaExiste = true}
            })
            if (materiaJaExiste) {
                return 'Disciplina duplicada! Os nomes não podem se repetir.'
            }
        }
    
        if (typeof novaMateria.peso !== 'number') {novaMateria.peso = parseFloat(novaMateria.peso[0].replace(',', '.'))}
        if (typeof novaMateria.nota !== 'number') {novaMateria.nota = parseFloat(novaMateria.nota[0].replace(',', '.'))}
        if (isNaN(novaMateria.peso) || isNaN(novaMateria.nota)) {
            return 'Ops! Certeza que preencheu o peso e a nota com números?'
        }
        if (novaMateria.peso <= 0) {
            return 'Valor estranho para colocar no peso, não?'
        }     
        if (novaMateria.nota < 0) {
            return 'Valor estranho para colocar na nota, não?'
        }
        return false
    }

    function verificarCadernoVazio(listaMaterias) {
        let cadernoEstaVazio = true
        listaMaterias.forEach((item) => {
            if ((item.disciplina !== ' ') && (item.disciplina !== 'Vazio')) {
                cadernoEstaVazio = false
            }
        })
        return cadernoEstaVazio
    }

    function adcMateria(novaMateria) {
        const msg = validarDados(novaMateria)
        if (msg) {
            setMsgErro(msg)
            // setTimeout(() => {
            //     animacoes.animacaoErroInput()
            // }, 200);
            return
        }
        let jaAchouEspacoVazio = false
        setMaterias((prevListaMaterias) => {
            const listaMateriasNovas = prevListaMaterias.map((prevMateria) => {
                if (!jaAchouEspacoVazio && (prevMateria.disciplina === 'Vazio' || prevMateria.disciplina === ' ')) {
                    jaAchouEspacoVazio = true
                    novaMateria.id = prevMateria.id
                    return novaMateria
                }
                return prevMateria
            })
            if (!jaAchouEspacoVazio) {
                // Se ja passou pelo map acima e ainda não encontrou nenhum espaço vazio
                // então precisa dar um append da novaMateria
                novaMateria.id = prevListaMaterias[prevListaMaterias.length - 1].id + 1 // pega o id do ultimo elemento e soma 1
                listaMateriasNovas.push(novaMateria)
            }
            return listaMateriasNovas
        })
        document.querySelectorAll('input').forEach(item => item.value = '')
        document.querySelectorAll('input.disciplina')[1].focus()
        setMsgErro(false)
        setCadernoVazio(false)
        setDisciplinaInput({
            'disciplina':'',
            'peso':'',
            'nota':''
        })
        animacoes.sairInputMateria()
    }

    function altMateria(novaMateria) {
        const msg = validarDados(novaMateria)
        if (msg) {setMsgErro(msg)} else {
            setMaterias((prevListaMaterias) => {
                return prevListaMaterias.map((prevMateria) => {
                    if (prevMateria.disciplina === nomeOriginal) {
                        novaMateria.id = prevMateria.id
                        return novaMateria
                    }
                    return prevMateria
                })
            })

            document.querySelectorAll('input').forEach(item => item.value = '')
            document.querySelectorAll('input.disciplina')[1].focus()
            animacoes.animacaoSairAlterarMateria()
            setMsgErro(false)
            setDisciplinaInput({
                'disciplina':'',
                'peso':'',
                'nota':''
            })
        }
    }

    function rmMateria() {
        setMaterias((prevListaMaterias) => {
            // Removendo a materia da lista de materias
            const novaListaMaterias = []
            prevListaMaterias.forEach((prevMateria) => {
                if (prevMateria.disciplina !== nomeOriginal) {
                    novaListaMaterias.push(prevMateria)
                }
            })

            // Adicionando linhas em branco quanto necessário
            // para o caderno ter sempre no mínimo 6 linhas
            while (novaListaMaterias.length < 6) {
                const novoId = novaListaMaterias[novaListaMaterias.length - 1].id + 1
                // O codigo acima pega o ultimo id e adiciona 1 no sequencial
                novaListaMaterias.push({
                    'id': novoId,'disciplina': ' ', 'peso': ' ', 'nota': ' '
                })
            }

            // Se o caderno tiver apenas linhas em branco, então deve voltar ao seu estado inicial
            if (verificarCadernoVazio(novaListaMaterias)) {
                setCadernoVazio(true)
                return arrayMateriasInicial
            }
            return novaListaMaterias
        })

        document.querySelectorAll('input').forEach(item => item.value = '')
        document.querySelectorAll('input.disciplina')[1].focus()
        animacoes.animacaoSairAlterarMateria()
    }

    function resetMaterias() {
        setMaterias(arrayMateriasInicial)
        setCadernoVazio(true)
        setTimeout(() => {
            animacoes.animacaoSairLimparTudo()
        }, 300);
    }

    function irParaHomePage() {
        animacoes.animacaoCalcParaHome()
        setTimeout(() => {
            navigate('/')
        }, 600)
    }

    return (
        <>
        <Header
            inHomePage={false}
            irParaCalculadora={() => null}
            irParaHomePage={irParaHomePage}
            user = {user}
            login = {login}
            logout = {logout}
        />
        <div className='calculadora-page'>
            <main>
                <BtnGerarLink
                    salvarHistorico = {salvarHistorico}
                />
                <MostradorNota
                    notaFinal = {notaFinal}
                    resetMaterias = {resetMaterias}
                    cadernoVazio = {cadernoVazio}
                />
                <div className='fundo-input-materia'></div>
                <FolhasInputMateria
                    adcMateria = {adcMateria}
                    altMateria = {altMateria}
                    rmMateria = {rmMateria}
                    disciplinaInput = {disciplinaInput}
                    setDisciplinaInput = {setDisciplinaInput}
                    msgErro = {msgErro}/>
                <Caderno
                    materias = {materias}
                    disciplinaInput = {disciplinaInput}
                    setDisciplinaInput = {setDisciplinaInput}
                    setNomeOriginal = {setNomeOriginal}
                />
            </main>
        </div>
        </>
    )
}
