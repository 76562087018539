import React from 'react';

import './header.css'

import logo from '../imagens/logo4.svg'
import userImg from '../imagens/user.svg'
import googleLogo from '../imagens/google-logo.png'

export default function Header(props){
    return (
        <>
            <div className={`fundo-main ${props.inHomePage? '':'fundo-main-calculadora'}`}></div>
            <header>
                    <img
                        className='logo'
                        src={logo}
                        alt="Logo MeuCr"
                        onClick={() => props.irParaHomePage()}
                    />
                    <nav>
                        <span
                            className={`nav-link ${props.inHomePage? 'nav-link-selecionado': ''}`}
                            onClick={() => props.irParaHomePage()}
                        >Início</span>
                        <span
                            className={`nav-link ${props.inHomePage? '':'nav-link-selecionado'}`}
                            onClick={() => props.irParaCalculadora()}
                        >Calculadora</span>
                    </nav>
                    {
                        !props.user?
                            <div className="btn-login" onClick={() => props.login()}>
                                <img className="loginImg" src={googleLogo} alt="Botão de login"/> Entrar com Google
                            </div>
                        :
                            <div className="div-user">
                                <span>
                                    {props.user.displayName}
                                    {
                                        props.user.photoURL ?
                                        <img
                                            className="userImg"
                                            src={props.user.photoURL}
                                            alt="Icone de usuario"
                                        />
                                        :
                                        <img
                                            className="userNoImg"
                                            src={userImg}
                                            alt="Icone de usuario"
                                        />                                        
                                    }
                                </span>
                                <span onClick={() => props.logout()}>Encerrar sessão</span>
                            </div>
                    }
            </header>
        </>
    )
}