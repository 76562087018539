import { getFirestore, setDoc, getDoc, doc, serverTimestamp } from "firebase/firestore";

import { app } from './firebase'

function verificarCadernoVazio(listaMaterias) {
    let cadernoEstaVazio = true
    listaMaterias.forEach((item) => {
        if ((item.disciplina !== ' ') && (item.disciplina !== 'Vazio')) {
            cadernoEstaVazio = false
        }
    })
    return cadernoEstaVazio
}

function gerarHash(listaMaterias) {
    if (verificarCadernoVazio(listaMaterias)) {
        return false
    } else {
        let hash = ''
        let i = 0
        while (i < listaMaterias.length) {
            let materiaHash = ''
            const materia = listaMaterias[i]
            if ((materia.disciplina !== 'Vazio') && (materia.disciplina !== ' ')) {
                for (let item in materia) {
                    materiaHash += item + '{@@conteudoCampo@@}' + materia[item].toString() + '{@@campo@@}'
                }
                materiaHash = materiaHash.slice(0, materiaHash.length - 11)
                // Tirando o ultimo separador de campo

                // se não for o último elemento, então adiciona o separador de materia
                hash += materiaHash + '{@@materia@@}'
            }
            i += 1
        }
        hash = hash.slice(0, hash.length - 13)
        return `${btoa(hash)}`
    }
}

function gerarArrayMaterias(hash) {
    if (!hash) {
        return [
            {'id': 1,'disciplina': 'Vazio', 'peso': 'x', 'nota': 'x'},
            {'id': 2,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
            {'id': 3,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
            {'id': 4,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
            {'id': 5,'disciplina': ' ', 'peso': ' ', 'nota': ' '},
            {'id': 6,'disciplina': ' ', 'peso': ' ', 'nota': ' '}
        ]
    }
    
    let listaMaterias = []
    
    const decodedHash = atob(hash)

    decodedHash.split('{@@materia@@}').forEach((materia) => {
        let materiaJson = {}
        materia.split('{@@campo@@}').forEach((campos) => {
            let listaConteudo = campos.split('{@@conteudoCampo@@}')
            if ((listaConteudo[0] === 'nota') || (listaConteudo[0] === 'peso') || (listaConteudo[0] === 'id')){
                materiaJson[listaConteudo[0]] = parseFloat(listaConteudo[1])
            } else {
                materiaJson[listaConteudo[0]] = listaConteudo[1]
            }
        })
        listaMaterias.push(materiaJson)
    })

    while (listaMaterias.length < 6) {
        const novoId = listaMaterias[listaMaterias.length - 1].id + 1
        // O codigo acima pega o ultimo id e adiciona 1 no sequencial
        listaMaterias.push({
            'id': novoId,'disciplina': ' ', 'peso': ' ', 'nota': ' '
        })
    }

    return listaMaterias
}

export default class Database {
    constructor() {
        this.db = getFirestore(app);
    }

    salvarHistorico(email, materias) {
        if (email){
            setDoc(
                doc(this.db, 'historico', email),
                {
                    'criado_em': serverTimestamp(),
                    'hash': gerarHash(materias)
                }
            )
        }
    }

    carregarHistorico(email, setMaterias) {
        if (email) {
            getDoc(doc(this.db, 'historico', email)).then(querySnapshot => {
                const hash = querySnapshot.data().hash
                setMaterias(gerarArrayMaterias(hash))
            })
        }
    }
}