export default class animacoesLoadingClass {
    animacaoEntradaHomePage(){
        document.querySelector('body').style.overflow = 'hidden'
        document.querySelector('.fundo-main').style.height = 'var(--altura-header-homePage)'

        document.querySelector('.fundo-main').style.animation = 'slideInFromTop 350ms'
        document.querySelector('.hero-texto').style.animation = 'var(--slideInFromTop)'
        setTimeout(() => {
            document.querySelector('.seta-cima').style.animation = 'fadeIn 250ms'
            document.querySelector('.seta-cima').style.visibility = 'visible'
        }, 200)
        setTimeout(() => {
            document.querySelector('.quadrado-amarelo').style.animation = 'var(--slideInFromBottom)'
            document.querySelector('.quadrado-amarelo').style.visibility = 'visible'
        }, 200)
        setTimeout(() => {
            document.querySelector('.container-FolhasInputMateria').style.animation = 'var(--slideInFromBottom)'
            document.querySelector('.container-FolhasInputMateria').style.visibility = 'visible'
            document.querySelector('.container-input-materia').style.visibility = 'visible'
        }, 300)
        setTimeout(() => {
            document.querySelector('.seta-baixo').style.animation = 'fadeIn 250ms'
            document.querySelector('.seta-baixo').style.visibility = 'visible'
        }, 350)
        setTimeout(() => {
            document.querySelector('.quadrado-azul').style.animation = 'var(--slideInFromBottom)'
            document.querySelector('.quadrado-azul').style.visibility = 'visible'
        }, 350)
        setTimeout(() => {
            document.querySelector('.quadrado-vermelho').style.animation = 'var(--slideInFromBottom)'
            document.querySelector('.quadrado-vermelho').style.visibility = 'visible'
        }, 400)
        setTimeout(() => {
            document.querySelector('.container-MostradorNotaHero').style.visibility = 'visible'
            document.querySelector('.container-MostradorNotaHero').style.transform = 'rotate(6deg)'
            document.querySelector('.container-MostradorNotaHero').style.animation = 'var(--slideInFromBottomWithRotate)'
        }, 450)
        setTimeout(() => {
            document.querySelector('.container-Caderno').style.animation = 'var(--slideInFromBottom)'
            document.querySelector('.container-Caderno').style.visibility = 'visible'
            document.querySelector('.parent-caderno').style.visibility = 'visible'
        }, 500)
        if (window.innerWidth > 640) {
            setTimeout(() => {
                document.querySelector('.btn-cta').style.animation = 'var(--slideInFromBottom)'
                document.querySelector('.btn-cta').style.visibility = 'visible'
            }, 500)
        } else {
            setTimeout(() => {
                document.querySelector('.btn-cta').style.animation = 'var(--slideInFromBottom)'
                document.querySelector('.btn-cta').style.visibility = 'visible'
            }, 700)
        }
        return
    };

    animacaoHomePageParaCalc() {
        document.querySelector('.btn-cta').style.animation = 'slideOutToBottom 400ms'
        setTimeout(() => {document.querySelector('.btn-cta').style.visibility = 'hidden'}, 200)
        document.querySelector('.container-MostradorNotaHero').style.transform = 'rotate(0)'
        document.querySelector('.container-MostradorNotaHero').style.animation = 'slideOutToBottom 400ms'
        setTimeout(() => {document.querySelector('.container-MostradorNotaHero').style.visibility = 'hidden'}, 150)
        setTimeout(() => {
            document.querySelector('.container-Caderno').style.animation = 'slideOutToBottom 400ms'
            setTimeout(() => {
                document.querySelector('.container-Caderno').style.visibility = 'hidden'
                document.querySelector('.parent-caderno').style.visibility = 'hidden'
            }, 200)
        }, 100)
        setTimeout(() => {
            document.querySelector('.seta-cima').style.animation = 'fadeOut 250ms'
            document.querySelector('.seta-cima').style.visibility = 'hidden'
        }, 150)
        setTimeout(() => {
            document.querySelector('.quadrado-vermelho').style.animation = 'slideOutToBottom 400ms'
            setTimeout(() => {document.querySelector('.quadrado-vermelho').style.visibility = 'hidden'}, 200)
        }, 150)
        setTimeout(() => {
            document.querySelector('.seta-baixo').style.animation = 'fadeOut 250ms'
            document.querySelector('.seta-baixo').style.visibility = 'hidden'
        }, 200)
        setTimeout(() => {
            document.querySelector('.fundo-main').style.height = 'var(--altura-header-calculadora)'
            document.querySelector('.hero-texto').style.animation = 'slideOutToTop 400ms'
        }, 250)
        setTimeout(() => {
            document.querySelector('.container-FolhasInputMateria').style.animation = 'slideOutToBottom 300ms'
        }, 300)
        setTimeout(() => {
            document.querySelector('.quadrado-azul').style.animation = 'slideOutToBottom 300ms'
        }, 300)
        setTimeout(() => {
            document.querySelector('.quadrado-amarelo').style.animation = 'slideOutToBottom 300ms'
        }, 350)
        return;
    };

    animacaoEntradaCalc() {
        setTimeout(() => {document.querySelector('body').style.overflowY = 'visible'}, 700)
        setTimeout(() => {
            document.querySelector('.parent-caderno').style.animation = 'var(--slideInFromBottom)'
            document.querySelector('.parent-caderno').style.visibility = 'visible'
        }, 150)
        setTimeout(() => {
            document.querySelector('.container-input-materia').style.animation = 'var(--slideInFromBottom)'
            document.querySelector('.container-input-materia').style.visibility = 'visible'
            document.querySelector('.btn-gerar-link').style.visibility = 'visible'
            document.querySelector('.btn-gerar-link').style.animation = 'slideInFromTop 300ms'
        }, 300)
        setTimeout(() => {
            document.querySelector('.mostradorNota').style.visibility = 'visible'
            document.querySelector('.limparMaterias').style.visibility = 'visible'
            document.querySelector('.limparMaterias').style.animation = 'slideInFromTop 600ms'
            document.querySelector('.mostradorNota').style.animation = 'slideInFromTopWithRotate 600ms'         
        }, 450)
        return;
    };

    animacaoCalcParaHome() {
        document.querySelector('body').style.overflow = 'hidden'
        document.querySelector('.parent-caderno').style.animation = 'slideOutToBottom 400ms'
        setTimeout(() => {
            document.querySelector('.parent-caderno').style.visibility = 'hidden'
        }, 200)
        setTimeout(() => {
            document.querySelector('.container-input-materia').style.animation = 'slideOutToBottom 400ms'
            document.querySelector('.btn-gerar-link').style.animation = 'slideOutToBottom 400ms'
            setTimeout(() => {
                document.querySelector('.container-input-materia').style.visibility = 'hidden'
                document.querySelector('.btn-gerar-link').style.visibility = 'hidden'
            }, 200)
        }, 200)
        setTimeout(() => {
            document.querySelector('.limparMaterias').style.animation = 'slideOutToTop 500ms'
            document.querySelector('.mostradorNota').style.animation = 'slideOutToTop 500ms'
        }, 400)
        return;
    }

    animacaoAlterarMateria(e) {
        const listaLinhaSelecionada = [...e.target.parentElement.children]
        const inputSegundaFolha = document.querySelectorAll('.input-materia')[0]
        listaLinhaSelecionada.forEach((domElem) => {
            inputSegundaFolha.querySelectorAll('input').forEach(inputElem => {
                if (domElem.className.split(' ')[0] === inputElem.className) {
                    // A condicao acima precisa do split pois a class de nota pode ser 'nota nota-alta' por ex
                    inputElem.value = domElem.innerHTML
                }
            })
        })
        if (e.target.parentElement.firstChild.innerHTML !== 'Vazio') {
            if (window.innerWidth <= 640) {
                document.querySelector('.fundo-input-materia').style.visibility = 'visible'
                document.querySelector('.container-input-materia').style.top = '20vh'
                setTimeout(() => {
                    document.querySelectorAll('.input-materia')[1].style.animation = 'slideOutToBottomWithoutFadeOut 1200ms'
                    document.querySelectorAll('.input-materia')[0].style.transform = 'rotate(0)'
                }, 350);
            } else {
                document.querySelectorAll('.input-materia')[1].style.animation = 'slideOutToBottomWithoutFadeOut 1200ms'
                setTimeout(() => {
                    document.querySelectorAll('.input-materia')[0].style.transform = 'rotate(0)'
                }, 20);
            }
            
            setTimeout(() => {
                document.querySelectorAll('.input-materia')[1].style.visibility = 'hidden'
            }, 400);
        }
    }

    animacaoSairAlterarMateria() {
        document.querySelectorAll('.input-materia')[1].style.animation = 'slideInFromBottom 300ms'
        document.querySelectorAll('.input-materia')[0].style.transform = 'rotate(-5deg)'
        document.querySelectorAll('.input-materia')[1].style.visibility = 'visible'
        if (window.innerWidth <= 640) {
            document.querySelector('.container-input-materia').style.top = '83vh'
            document.querySelector('.fundo-input-materia').style.visibility = 'hidden'
        }
    }

    animacaoLimpartudo() {
        document.querySelector('.mostradorNota').style.animation = 'slideOutToTop 500ms'
        setTimeout(() => {
            document.querySelector('.mostradorNota').style.visibility = 'hidden'
        }, 450);
    }

    animacaoSairLimparTudo() {
        document.querySelector('.mostradorNota').style.animation = 'slideInFromTop 500ms'
        setTimeout(() => {
            document.querySelector('.mostradorNota').style.visibility = 'visible'
        }, 450);
    }

    animacaoErroInput() {
        document.querySelector('.container-input-materia').style.animation = 'shake 200ms 2 forwards'
    }

    entradaLinkGerado(){
        document.querySelector('.fundo-mostrador-link').style.animation = 'fadeIn 250ms'
        document.querySelector('.mostrador-link').style.visibility = 'visible'
        document.querySelector('.mostrador-link').style.animation = 'slideInFromBottom 250ms'
    }

    carregarInputMateria() {
        document.querySelector('.fundo-input-materia').style.visibility = 'visible'
        document.querySelector('.container-input-materia').style.top = '20vh'
    }

    sairInputMateria() {
        if (window.innerWidth <= 640) {
            document.querySelector('.container-input-materia').style.top = '83vh'
            document.querySelector('.fundo-input-materia').style.visibility = 'hidden'
        }
    }
};