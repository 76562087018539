import React from 'react';
import './inputMateria.css'
import animacoesLoadingClass from '../animacoes'

function InputMateria(props) {
    const animacoes = new animacoesLoadingClass()

    const handleMateria  = (event) => {
        props.setDisciplinaInput((prevMateria) => {
            return {...prevMateria, [event.target.className]:[event.target.value]}
        })
    }

    function handleEnter(e) {
        if (e.key === 'Enter') {
            props.segundaFolha? props.altMateria(props.disciplinaInput) : props.adcMateria(props.disciplinaInput)
        }
    }


    return (
        <div className={'input-materia' + (props.segundaFolha ? ' input-segunda-folha': '')}>
            <table><tbody>
                <tr onClick={
                    window.innerWidth > 640? () => null : () => animacoes.carregarInputMateria()
                }>
                    <td></td>
                    <td className='titulo-input-materia'>{props.adicionarMateria? 'Adicionar': 'Alterar'} disciplina</td>
                </tr>
                <tr className={'input-materia-linha-vazia'}>
                    <td></td>
                    {props.segundaFolha ?
                        <td onClick = {props.rmMateria}>
                            <span style={{color: 'var(--vermelho)', fontSize: '0.8em'}}>&#x2715;</span> Remover disciplina
                        </td>
                        :
                        <td></td>
                    }
                </tr>
                <tr className='input-materia-linha-input'>
                        <td></td><td>Nome: <input name='disciplina' className='disciplina' onKeyDown={handleEnter} onChange={handleMateria}/></td>
                </tr>
                <tr className={'input-materia-linha-vazia'}>
                    <td></td><td></td>
                </tr>
                <tr className='input-materia-linha-input'>
                    <td></td><td>Peso: <input name='peso' className='peso' onKeyDown={handleEnter} onChange={handleMateria}/></td>
                </tr>
                <tr className={'input-materia-linha-vazia'}>
                    <td></td><td></td>
                </tr>
                <tr className='input-materia-linha-input'>
                    <td></td><td>Nota: <input name='nota' className='nota' onKeyDown={handleEnter} onChange={handleMateria}/></td>
                </tr>
                <tr className={'input-materia-linha-vazia'}>
                    <td></td><td></td>
                </tr>
                <tr>
                    <td></td>
                    <td className='input-materia-btn' style={{justifyContent: (props.segundaFolha || window.innerWidth <= 640)? 'space-between' : 'flex-end'}}>
                        {props.segundaFolha &&
                        <button
                            className='btn-cancelar'
                            onClick={() => {
                                animacoes.animacaoSairAlterarMateria()
                                props.setDisciplinaInput({
                                    'disciplina':'',
                                    'peso':'',
                                    'nota':''
                                })
                            }}>Cancelar</button>
                        }
                        {(!props.segundaFolha && window.innerWidth <= 640) &&
                        <button
                            className='btn-cancelar'
                            onClick={() => {
                                animacoes.sairInputMateria()
                                props.setDisciplinaInput({
                                    'disciplina':'',
                                    'peso':'',
                                    'nota':''
                                })
                            }}>Cancelar</button>
                        }
                        <button
                            className='btn-adc-materia'
                            onClick={() => {
                                props.segundaFolha? props.altMateria(props.disciplinaInput) : props.adcMateria(props.disciplinaInput)
                            }
                        }>
                            {props.adicionarMateria? 'Adicionar': 'Alterar'}
                        </button>
                    </td>
                </tr>
            </tbody></table>
        </div>
    )
}


export default function FolhasInputMateria(props){
    return (
        <div className='container-input-materia'>
            {props.msgErro && <p className='msgErro'>{props.msgErro}</p>}
            <InputMateria
                adicionarMateria = {false}
                segundaFolha={true}
                altMateria = {props.altMateria}
                rmMateria = {props.rmMateria}
                disciplinaInput = {props.disciplinaInput}
                setDisciplinaInput = {props.setDisciplinaInput}
            />
            <InputMateria
                adicionarMateria = {true}
                adcMateria = {props.adcMateria}
                disciplinaInput = {props.disciplinaInput}
                setDisciplinaInput = {props.setDisciplinaInput}
            />
        </div>
    )
}