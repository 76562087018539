import React from 'react';
import './caderno.css'
import topoCaderno from '../imagens/topo-caderno.svg'
import animacoesLoadingClass from '../animacoes'

export default function Caderno(props) {
    const animacoes = new animacoesLoadingClass()
    const materias = props.materias
    const listaMaterias = materias.map(materia => {
        let notaMostrador = materia['nota'].toString().replace('.', ',')
        let nota = parseFloat(materia['nota'].toString().replace(',', '.'))

        let classNota = nota >= 9 ? ' nota-alta' : (nota < 4 ? ' nota-baixa' : '')
        // Tem que colocar um espaço antes para concatenar a string

        if (materia.disciplina === ' ') {
            return (
                <tr key = {materia.id} className='linha-vazia'>
                    <td className='disciplina'>{materia.disciplina}</td>
                    <td className='peso'>{materia.peso}</td>
                    <td className={'nota' + classNota  }>{notaMostrador}</td>
                </tr>
            )
        }
        return (
            <tr
                key = {materia.id}
                onClick={(e) => {
                    const linhaSelecionada = [...e.target.parentElement.children]
                    linhaSelecionada.forEach((domElem) => {
                        if (domElem.className === 'disciplina') {
                            props.setNomeOriginal(domElem.innerHTML)
                        }
                        props.setDisciplinaInput((prevMateria) => {
                            return {...prevMateria, [domElem.className.split(' ')[0]]:[domElem.innerHTML]}
                        })
                    })
                    animacoes.animacaoAlterarMateria(e)
                }}>
                <td className='disciplina'>{materia.disciplina}</td>
                <td className='peso'>{materia.peso}</td>
                <td className={'nota' + classNota}>{notaMostrador}</td>
            </tr>
        )
    })

    return (
        <div className="parent-caderno">
            <img src={topoCaderno} alt='topo caderno hero' />
            <table className='caderno'>
                <thead><tr>
                    <th className='disciplina'>Disciplina</th>
                    <th className='peso'>Peso</th>
                    <th className='nota'>Nota</th>
                </tr></thead>
                <tbody>
                    {listaMaterias}
                </tbody>
            </table>
        </div>
    )
}
