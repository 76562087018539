import './mostradorNotaHero.css'

import React from 'react';

function MostradorNotaHero() {
    let nota = 7.56
    return (
        <div className='container-MostradorNotaHero'>
            <div></div>
            <div>Nota: <span className='span-mostrador-nota'>{nota}</span></div>
        </div>
    )
}

export default MostradorNotaHero;