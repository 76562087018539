import { getAuth } from 'firebase/auth'
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth'

import { app } from './firebase'

import Database from './db'

export default class UserClass {
    constructor() {
        this.auth = getAuth(app)
        this.currentUser = this.auth.currentUser
        this.historico = null
    }

    login(user, setUser, setMaterias){
        if (!user) {
            const provider = new GoogleAuthProvider()
            provider.setCustomParameters({
                prompt: 'select_account'
            })
        
            signInWithPopup(this.auth, provider)
            .then((resp) => {
                setUser(resp.user)

                const db = new Database()
                db.carregarHistorico(resp.user.email, setMaterias)
            })
        }
    }

    logout(user, setUser) {
        if (user) {
            this.auth.signOut().then(() => {
                setUser(false)
                // setMaterias(arrayMateriasInicial)
            })
        }
    }
}